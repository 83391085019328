document.addEventListener('DOMContentLoaded',function(){
  const screenSize = window.innerWidth
  const linkLists = document.querySelectorAll('.link-list') || [];
  for (let linkList of linkLists) {
    const list = linkList.querySelector('.link-list__wrapper') || null
    const items = linkList.querySelectorAll('.link-list__item') || []
    const btn = linkList.querySelector('.link-list__btn') || null
    const itemNum = items.length || null

    if (list && btn && itemNum && itemNum > 7 && screenSize <= 992){
      btn.classList.remove('d-none')
      list.classList.add('link-list__wrapper--hidden')

      btn.addEventListener('click', function (){
        btn.classList.add('d-none')
        list.classList.remove('link-list__wrapper--hidden')
      })
    }
  }
})
